import { PatientModel } from "../models/Patient.model"

interface IUpperMenuOptions {
    name: string,
    disabled: boolean,
    dataTestId: string,
    referenceId: string
}

export enum REFERENCE_IDS {
    PATIENT_DATA = "btn-patient-data",
    PERSON_IN_CHARGE_DATA = "btn-person-charge",
    SERVICE_DATA = "btn-service-data",
    ECONOMIC_DATA = "btn-economic-data",
    WORK_PLAN_DATA = "btn-workplan"
}

export enum URL_PARAMS_NAME {
    TAB = "tab"
}

export const getUpperMenuOptions = ({
    allDataPatient
}: {
    allDataPatient: PatientModel | undefined
}) => {
    return [
        {
            name: 'Datos del paciente',
            disabled: false,
            dataTestId: 'btn-patient-data',
            referenceId: REFERENCE_IDS.PATIENT_DATA
        },
        {
            name: 'Datos del responsable', disabled: false,
            dataTestId: 'btn-person-charge',
            referenceId: REFERENCE_IDS.PERSON_IN_CHARGE_DATA
        },
        {
            name: 'Datos del servicio', disabled: false,
            dataTestId: 'btn-service-data',
            referenceId: REFERENCE_IDS.SERVICE_DATA
        },
        {
            name: 'Datos económicos',
            disabled: allDataPatient && (!allDataPatient.service?.eventNumber || allDataPatient.service?.homeEnabled === 0),
            dataTestId: 'btn-economic-data',
            referenceId: REFERENCE_IDS.ECONOMIC_DATA
        },
        {
            name: 'Plan de Trabajo',
            disabled: allDataPatient && (!allDataPatient.service?.eventNumber || allDataPatient.service?.homeEnabled === 0),
            dataTestId: 'btn-workplan',
            referenceId: REFERENCE_IDS.WORK_PLAN_DATA
        },
    ] as IUpperMenuOptions[]
}