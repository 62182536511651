import Chip from "components/Chip"
import "./index.css"

export const AttentionStatusChip = ({
    attentionStatusCode
}: {
    attentionStatusCode: string
}) => {

    let labelToChoose: string = "";
    let variant: string = "";
    if (attentionStatusCode.match(/pendiente/i)) {
        labelToChoose = 'Pendiente'
        variant = "pending chip-inlined"
    } else if (attentionStatusCode.match(/atenc/i)) {
        labelToChoose = 'En Progreso'
        variant = "in-progress chip-inlined"
    } else if (attentionStatusCode.match(/dado de alta/i)) {
        labelToChoose = 'Atendido'
        variant = "completed chip-inlined"
    }
    return (
        <Chip onClose={() => { }} className={`${variant} justify-center items-center`} label={labelToChoose} />
    )
}