import "./index.css"

export const ToolTipWrapper = ({ children, text }: {
    children: any,
    text: string
}) => {
    return <div className="tooltip">
        {children}
        <span className="tooltiptext">{text}</span>
    </div>;
}