import { v4 as uuidv4 } from 'uuid';
import Button from 'components/Button';
import ButtonToggle from 'components/ButtonToggle';
import IconPlusCircle from 'assets/icons/icon_plus_circle.svg';
import { useEffect, useState } from 'react';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { RegisterSupplyModel, SuppliesOptionsModel, SuppliesServiceModel, useDeleteSuppliesService, useGetSuppliesService, useRegisterSuppliesService, useUpdateSuppliesService, useGetSuppliesOptionsSearch, useNotificationSuppliesService } from '../hooks/useSupplies';
import PrescriptionElement from './PrescriptionElement';
import { concat } from 'rxjs';
import Loader from 'components/Loader';
import { onAlert } from 'components/Alert';
import useUser from 'features/shared/hooks/useUser';
import { AVAILABLE_VIAS_MAPPED } from './utils';

interface SuppliesProps {
  patientData?: PatientModel;
  isPlainForm?: boolean;
  deleteValue?: boolean;
  onChangeSupplies?: (list: SuppliesServiceModel[], isValid: boolean) => void;
}

const Supplies: React.FC<SuppliesProps> = ({
  patientData,
  deleteValue,
  isPlainForm = false,
  onChangeSupplies
}) => {
  const { userCurrent } = useUser();

  const [listSuppliesOptions, setListSuppliesOptions] = useState<SuppliesOptionsModel[]>([]);
  const [listSuppliesService, setListSuppliesService] = useState<SuppliesServiceModel[]>([]);
  const { mutateAsync: getSuppliesOptionsSearch } = useGetSuppliesOptionsSearch();
  const { mutateAsync: getSuppliesService, isLoading: loadingSuppliesService } = useGetSuppliesService();
  const { mutateAsync: registerSuppliesService, isLoading: loadingRegisterSuppliesService } = useRegisterSuppliesService();
  const { mutateAsync: updateSuppliesService, isLoading: loadingUpdateSuppliesService } = useUpdateSuppliesService();
  const { mutateAsync: deleteSuppliesService, isLoading: loadingDeleteSuppliesService } = useDeleteSuppliesService();
  const { mutateAsync: notificationSuppliesService, isLoading: loadingNotificationSuppliesService } = useNotificationSuppliesService();
  const [registerUpdateLoading, setregisterUpdateLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  useEffect(() => {
    onGetSuppliesService();
  }, [patientData?.service])

  const onGetSuppliesService = async () => {
    if (patientData?.service) {
      const response = await getSuppliesService(patientData?.service?.id);

      if (response.data.length > 0) {
        if (isPlainForm) {
          setListSuppliesService(response.data.filter((e: SuppliesOptionsModel) => e?.type === 1)); //type is for medicaments | 2 for supplies);
        } else {
          setListSuppliesService(response.data);
        }
      }
    }
  }

  const onSubmitSuppliesService = async () => {
    setregisterUpdateLoading(true)
    const registerArraySupplies: RegisterSupplyModel[] = [];
    const updateArraySupplies: RegisterSupplyModel[] = [];

    listSuppliesService.forEach(e => {
      const viaValue = `${AVAILABLE_VIAS_MAPPED[e.via || ""]?.value || e.via}`;
      const element = {
        supplyId: e.supplyId,
        daysOfTreatment: e.daysOfTreatment || e.days_of_treatment,
        quantity: e.quantity,
        schedule: e.schedule,
        medicationStartTime: e.medicationStartTime,
        current_therapeutics: !!e.current_therapeutics,
        via: viaValue
      };
      if (e.planId === 0) {
        registerArraySupplies.push(element);
      } else {
        updateArraySupplies.push(element);
      }
    });

    const multipleRegisterSupplies = registerArraySupplies.map(item => registerSuppliesService({ serviceId: patientData?.service?.id, data: item }));
    const multipleUpdateSupplies = updateArraySupplies.map(item => updateSuppliesService({ serviceId: patientData?.service?.id, supplyId: item.supplyId, data: item })); // TODO
    const bodyNotification = {
      "patientId": patientData?.userId,
      "userId": userCurrent?.id,
      "planId": patientData?.service?.id
    };
    const notificationSupplies = notificationSuppliesService(bodyNotification);

    const setResponses: string[] = [];

    concat(...multipleRegisterSupplies, ...multipleUpdateSupplies).subscribe(({
      next: (item: any) => {
        setResponses.push(!!item.data ? '' : item);
      },
      error: (err: any) => {
        onAlert.error(err);
      },
      complete: () => {
        const error = setResponses.find(e => e !== '');

        if (!!error) {
          setregisterUpdateLoading(false)
          if (error.match("¡Ups! \"via\" must be one of")) {
            onAlert.error("Error: Debe especificar una vía válida.");
          } else if (error.match(/la prescripcion no existe/i)) {
            onAlert.error("Error: No puedes cambiar el nombre del medicamento luego de asignado.");
          } else {
            onAlert.error(error);
          }
        } else {
          setregisterUpdateLoading(false)
          onAlert.success('Registro exitoso');
        }
        onGetSuppliesService();
      }
    }));
  }

  const onDeleteSuppliesService = async (id: string | number, type: 'supplyId' | 'tempId') => {
    if (type === 'supplyId') {
      deleteSuppliesService({ serviceId: patientData?.service?.id, supplyId: id }).then(res => {
        if (res.data) {
          onAlert.success('Actualización exitosa');
          setListSuppliesService([...listSuppliesService].filter((e) => e.supplyId !== id));
          const bodyNotification = {
            "patientId": patientData?.userId,
            "userId": userCurrent?.id,
            "planId": patientData?.service?.id
          };
          const notificationSupplies = notificationSuppliesService(bodyNotification);
        } else {
          onAlert.error(res);
        }
      });
      onGetSuppliesService()
    }

    if (type === 'tempId') {
      setListSuppliesService([...listSuppliesService].filter((e) => e.tempId !== id));
    }
  }
  const onDeleteValue = async (id: string | number, type: 'supplyId' | 'tempId') => {
    if (type === 'supplyId') {
      setListSuppliesService([...listSuppliesService].filter((e) => e.supplyId !== id));
    }
    if (type === 'tempId') {
      setListSuppliesService([...listSuppliesService].filter((e) => e.tempId !== id));
    }
  }

  const newPrescriptionElement = () => {
    const newPrescription: SuppliesServiceModel = {
      "planId": 0,
      "supplyId": 0,
      "quantity": 0,
      "schedule": "",
      "current_therapeutics": 0,
      "medicationStartTime": "",
      "daysOfTreatment": "",
      "supplyType": 0,
      "via": "",
      "supplyName": "",
      "createdAt": "",
      "days_of_treatment": undefined,
      "tempId": uuidv4(),
    };

    setListSuppliesService([...listSuppliesService, newPrescription]);
  }

  useEffect(() => {
    if (isPlainForm && onChangeSupplies) {
      onChangeSupplies(listSuppliesService, !isFormsInvalid());
    }
  }, [listSuppliesService])

  const isFormsInvalid = () => listSuppliesService.map((e: SuppliesServiceModel) => !!e.supplyId && !!e.quantity && !!e.schedule && !!e.medicationStartTime).includes(false);

  const handleSearch = (value: string) => {
    setIsLoadingSearch(true);
    getSuppliesOptionsSearch({
      serviceId: patientData?.service?.id,
      searchText: value
    }).then((resp: any) => {
      const transformedResponse = resp?.data?.map((data: any) => ({
        value: data?.id,
        label: data?.name,
        via: data?.via,
        type: data?.type
      }));
      setListSuppliesOptions([...transformedResponse]);
    }).finally(() => setIsLoadingSearch(false));
  }

  return (
    <>
      {loadingRegisterSuppliesService || loadingUpdateSuppliesService || loadingDeleteSuppliesService || registerUpdateLoading && <Loader />}

      <div
        className={
          isPlainForm ? '' : 'border-solid border-[1px] border-grey-200 rounded-[16px] p-4'}
      >
        <div className="flex justify-between items-center">
          <span className='text-body1'>{isPlainForm ? '' : 'Prescripciones'}</span>
          <ButtonToggle icon={IconPlusCircle} isActive={true} disabled={false} onClick={newPrescriptionElement} data-testid='add_prescription' >
            Agregar
          </ButtonToggle>
        </div>

        {listSuppliesService.length > 0 && (
          <>
            <div className='mb-4'>
              {
                listSuppliesService.map((e: SuppliesServiceModel, index) =>
                  <PrescriptionElement
                    key={e.supplyId || e.tempId}
                    isPlainForm={isPlainForm}
                    data={e}
                    supplyOptions={listSuppliesOptions}
                    onChange={(el: SuppliesServiceModel) => {
                      const updateListSupplies = [...listSuppliesService];
                      updateListSupplies[index] = el;

                      setListSuppliesService(updateListSupplies);
                    }}
                    onChangeSearch={handleSearch}
                    onDelete={() => {
                      if (deleteValue) {
                        if (e.tempId) {
                          onDeleteValue(e.tempId, 'tempId');
                        }
                        if (e.supplyId) {
                          onDeleteValue(e.supplyId, 'supplyId');
                        }
                      } else {
                        if (e.tempId) {
                          onDeleteSuppliesService(e.tempId, 'tempId');
                        }
                        if (e.supplyId) {
                          onDeleteSuppliesService(e.supplyId, 'supplyId');
                        }
                      }
                    }}
                    isLoadingSearch={isLoadingSearch}
                  />
                )
              }
            </div>

            {
              !isPlainForm &&
              <Button
                variant='primary'
                size="small"
                disabled={isFormsInvalid()}
                onClick={onSubmitSuppliesService}
                type="button"
                data-testid="save-btn"
              >
                Guardar
              </Button>
            }

          </>
        )}
      </div>
    </>
  );
};

export default Supplies;
