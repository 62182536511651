import { useEffect, useState } from "react"
import CalendarIcon from 'assets/icons/icon_calendar.svg';
import DatePicker from 'react-datepicker'
import IconClock from 'assets/icons/icon_clock.svg';

const DatePickerWrap = (props: any) => {
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (props.selected) onHasValue(props.selected);
    if (props.startDate) onHasValue(props.startDate);
    if (props.endDate) onHasValue(props.endDate);

  }, [props.selected, props.startDate, props.endDate])

  const onHasValue = (val: any) => {
    setHasValue(val.toString().length > 0);
  }

  const onChangeSelect = (val: any) => {
    onHasValue(val);
    props.onChange(val);
  }

  return (
    <div className="flex flex-col	min-h-[56px]">
      <div
        className='flex-col	data-picker-content
          w-full max-w-full  text-body1 relative cursor-pointer
          flex	min-h-[56px] items-center justify-start
        '
      >

        <DatePicker
          className={`
          min-h-[56px]
            peer appearance-none
            px-[16px] !w-full  rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none text-body1
            hover:px-[calc(16px-1px)] hover:border-solid hover:border-[1px] hover:border-grey-400
            focus:px-[calc(16px-1px)] focus:border-solid focus:border-[1px] focus:border-[#003C73]
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'}
            ${props.className}
          `}
          onChange={onChangeSelect}
          onFocus={() => setIsFocus(!isFocus)}
          {...props}
        />

        <label
          className={`
            px-[16px] absolute text-gray-600 pointer-events-none
            transition duration-150 ease-out
            ${hasValue ? 'mb-6 ease-in text-caption text-neutral-600' : ''}
            ${isFocus ? 'text-caption mb-6' : ''}
            ${isValid ? '' : 'text-error-main'}
          `}
        >
          {props.label}
          {props.required ? (<span className="font-semibold">*</span>) : ''}
        </label>

        {
          props.showTimeSelectOnly
            ? <img src={IconClock} className="w-[24px] h-[24px] absolute right-[16px] " />
            : <img src={CalendarIcon} className={`${props?.inline && 'hidden'} w-[24px] h-[24px] absolute right-[16px] `} />
        }
      </div>
      {
        isValid ? (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-gray-600
            ${props.helperText ? '' : 'hidden'}
          `}>
            {props.helperText}
          </p>
        ) : (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-error-main
            ${hasValue && props.errorText ? '' : 'hidden'}
          `}>
            {hasValue ? 'Este campo es obligatorio' : props.errorText}
          </p>
        )
      }
    </div>
  )
}

export default DatePickerWrap;
