import moment, { isDate } from "moment";
import { IIncomingFields } from "./Table.utils";

export function calculateHospitalizationDays(item: IIncomingFields): string {
  if(!item.admissionDate || !item.hospitalizationDaysDiffRelease){
    return "--"
  }
  const date = new Date(item.admissionDate || "");
  const daysSinceServiceDate = isDate(date) ? moment(Date.now()).diff(date, 'days') : 0;
  const totalExpectedDays = parseInt(item.hospitalizationDaysDiffRelease || "0");
  const daysToShowConservative = daysSinceServiceDate > totalExpectedDays ? totalExpectedDays : daysSinceServiceDate;
  return daysToShowConservative.toString();
}