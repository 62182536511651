import moment from "moment";
import { Dispatch, SetStateAction } from "react";

export interface ISelectableOption {
    value: string | number,
    label: string
}

export function setChangedData({
    field,
    value,
    changeData,
    setdaysOfHospitalization
}: {
    field: any, value: any, changeData: any, setdaysOfHospitalization: Dispatch<SetStateAction<string>>
}) {
    switch (field) {
        case 'via':
            changeData.via = value;
            break;
        case 'supplyIdSearch':
            changeData.supplyId = value.value;
            changeData.supplyName = value.label;
            changeData.via = value.via;
            changeData.type = value.type;
            break;
        case 'quantity':
            changeData.quantity = Number(value);
            break;
        case 'medicationStartTime':
            changeData.medicationStartTime = moment(value).format('h:mm a').toUpperCase();
            break;
        case 'schedule':
            changeData.schedule = value;
            break;
        case 'current_therapeutics':
            changeData.current_therapeutics = value;
            break;
        case 'daysOfTreatment':
            setdaysOfHospitalization(value);
            changeData.daysOfTreatment = value;
            break;
    }
}

export const AVAILABLE_VIAS: ISelectableOption[] = [
    { "value": "via_intravenosa", "label": "Endovenoso" },
    { "value": "via_inhalatoria", "label": "Inhalatoria" },
    { "value": "via_intramuscular", "label": "Intramuscular" },
    { "value": "via_oral", "label": "Oral" },
    { "value": "via_subcutanea", "label": "Subcutánea" },
    { "value": "via_topica", "label": "Tópico" }
];


export const AVAILABLE_VIAS_MAPPED: {
    [key:string]: ISelectableOption
} = {
    'oral': AVAILABLE_VIAS[0],
    'intramuscular': AVAILABLE_VIAS[1],
    'intravenosa': AVAILABLE_VIAS[2],
    'subcutanea': AVAILABLE_VIAS[3],
    'topica': AVAILABLE_VIAS[4],
    'inhalatoria': AVAILABLE_VIAS[5],

    'Oral': AVAILABLE_VIAS[0],
    //'Intramuscular / Endovenoso': AVAILABLE_VIAS[1],
    'Intramuscular': AVAILABLE_VIAS[1],
    'Endovenoso': AVAILABLE_VIAS[2],
    'Subcutáneo': AVAILABLE_VIAS[3],
    'Tópico': AVAILABLE_VIAS[4],
    'Inhalador': AVAILABLE_VIAS[5],
};


export const SELECTABLE_QUANTITIES: ISelectableOption[] = [
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
    ]

export const SELECTABLE_SCHEDULE: ISelectableOption[] = [
    {value: "every_2h", label: "Cada 2 horas"},
    {value: "every_4h", label: "Cada 4 horas"},
    {value: "every_6h", label: "Cada 6 horas"},
    {value: "every_8h", label: "Cada 8 horas"},
    {value: "every_12h", label: "Cada 12 horas"},
    {value: "every_24h", label: "Cada 24 horas"},
]