interface ButtonToggleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  icon?: any;
}

const ButtonToggle: React.FC<ButtonToggleProps> = ({
  isActive,
  className,
  children,
  icon,
  ...props
}) => {

  const filterActive = 'invert(100%) sepia(0%) saturate(3451%) hue-rotate(56deg) brightness(115%) contrast(152%)';

  return (
    <button
      {...props}
      className={`
        flex
        min-w-[120px]
        min-h-[42px] rounded-[8px] text-button
        px-[16px] flex items-center 
        ${isActive ? 'text-white bg-primary-main' : 'bg-none text-black'}
        ${props.disabled ? 'opacity-60 cursor-default' : ''} 
        ${className}
      `}
      type="button"
    >
      {icon !== undefined && <img src={icon} alt='icon' className='mr-2 w-[16px] h-[16px]' style={isActive ? { filter: filterActive } : {}} />}

      {children}
    </button>
  )
}

export default ButtonToggle
