import React, { useState } from 'react';
import { BehaviorSubject } from 'rxjs';

import useUser from 'features/shared/hooks/useUser';
import { ISearchResult, PatientsModel } from 'features/shared/types/userType';
import Breadcrumbs from 'components/Breadcrumbs';

import Loader from '../../../components/Loader';
import { useGetListPatients } from 'features/Patients/hooks/usePatient';
import { ALLOWED_FILTERS, EXTRA_ACTIONS, IFiltersData } from './Table/TableUpperActions';
import { HEADER_SORT_ORDER_PATIENTS, onGetListPatients, useHandleDataLoading } from './index.utils';
import { IIncomingFields, mapIncomingPatientsListField } from './Table/Table.utils';
import { TableComponent } from './Table/Table';
import { AVAILABLE_ACTIONS } from 'global/constants/enums';
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from 'features/shared/utils/getBreadcrumbDetails';

const pageSize = 10;

const initialListPatients: PatientsModel = {
    currentPage: 1,
    data: [],
    total: 1,
    totalPages: 1,
};
const inputChange = new BehaviorSubject('');
const inputChange$ = inputChange.asObservable();

export const PatientsComponent: React.FC = () => {
    const { userCurrent } = useUser();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [initial, setIsInitial] = useState(true);
    const [filtersData, setFiltersData] = useState<IFiltersData>();
    const [listPatients, setListPatients] = useState<PatientsModel>(initialListPatients);
    const handleChangePage = (page: number) => {
        setCurrentPage(page);
    };

    const {
        isLoading: listPatientLoading,
        mutateAsync: mutateAsyncListPatients,
        isSuccess: listPatientLoadingSuccess,
    } = useGetListPatients();

    useHandleDataLoading({
        currentPage,
        userCurrent,
        searchMutation: mutateAsyncListPatients,
        searchFunction: onGetListPatients,
        setResults: setListPatients,
        filtersData,
        initial,
        setIsInitial,
        inputChange$,
        setCurrentPage
    });

    const showTableBody = (!listPatientLoading);
    const showLoader = (listPatientLoading);
    const hasSuccess = (listPatientLoadingSuccess);
    const elementsToShow: ISearchResult<IIncomingFields> = mapIncomingPatientsListField(listPatients);
    const {
        resultingElements
    } = getBreadcrumbDetails({
        location: location as any,
        from: BREADCRUMB_ORIGIN.PATIENTS,
        additionalData: {},
    });

    return (
        <React.Fragment>
            {showLoader && <Loader />}

            <Breadcrumbs
                name={""}
                currentPathName={""}
                elements={resultingElements}
            />

            <div
                data-testid="Patients"
                className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
            >
                <TableComponent
                    elementsToShow={elementsToShow}
                    pageSize={pageSize}
                    handleChangePage={handleChangePage}
                    setFiltersData={setFiltersData}
                    hasSuccess={hasSuccess}
                    showTableBody={showTableBody}
                    filters={[
                        ALLOWED_FILTERS.REGION,
                        ALLOWED_FILTERS.ATTENTION_DATE,
                        ALLOWED_FILTERS.TEXT
                    ]}
                    potentialActions={[
                        AVAILABLE_ACTIONS.EDIT_PATIENT,
                        AVAILABLE_ACTIONS.ATTENTIONS_HISTORY,
                    ]}
                    extraActions={[EXTRA_ACTIONS.ADD_PATIENT]}
                    allowedFields={HEADER_SORT_ORDER_PATIENTS}
                    resultsLabel='Pacientes'
                />
            </div>
        </React.Fragment>
    );
};
