import { FC } from 'react';
import { PatientsComponent } from './Patients';
import { HistoryComponent } from './History';
import { Attentions } from './Attentions';
import { useParams } from 'react-router-dom';

interface RouteParams {
  documentPatient: string
}

const ClinicHistoryPatients: FC<{
  type: 'patients' | 'attentions' | 'history' | 'patient_attention';
}> = ({
  type
}) => {
    const params = useParams<RouteParams>();

    if (type === 'patients') {
      return (<PatientsComponent />);
    }

    if (type === 'history') {
      return <HistoryComponent />;
    }

    if (type === 'attentions') {
      return (<Attentions />);
    }

    if (type === 'patient_attention') {
      return (<Attentions patientId={params.documentPatient} />);
    }

    return null;
  };

export default ClinicHistoryPatients;