import React, { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

import useUser from 'features/shared/hooks/useUser';
import { AttentionsModel, ISearchResult } from 'features/shared/types/userType';
import Breadcrumbs from 'components/Breadcrumbs';

import Loader from '../../../components/Loader';
import { useSearchPatientsAttentions } from 'features/Patients/hooks/usePatient';
import { ALLOWED_FILTERS, IFiltersData } from './Table/TableUpperActions';
import { HEADER_SORT_ORDER_ATTENTIONS_PATIENTS, HEADER_SORT_ORDER_ATTENTIONS_SPECIFIC_PATIENT, onGetPatientAttentions, useHandleDataLoading } from './index.utils';
import { IIncomingFields, mapIncomingAttentionFields } from './Table/Table.utils';
import { TableComponent } from './Table/Table';
import { AVAILABLE_ACTIONS } from 'global/constants/enums';
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from 'features/shared/utils/getBreadcrumbDetails';
import { useHistory, useParams } from 'react-router-dom';
import { useGetPatientData } from 'features/shared/hooks/useGetPatientFullData';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { DEFAULT_ALL_SERVICE_STATES } from 'features/shared/types/serviceStates';

const pageSize = 10;
const inputChange = new BehaviorSubject('');
const inputChange$ = inputChange.asObservable();

const initialPatientAttentions: AttentionsModel = {
  currentPage: 1,
  data: [],
  total: 1,
  totalPages: 1,
};

interface RouteParams {
  documentPatient: string
}

export const Attentions = ({
  patientId
}: {
  patientId?: string
}) => {
  const { userCurrent } = useUser();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initial, setIsInitial] = useState(true);
  const [filtersData, setFiltersData] = useState<IFiltersData>();
  const [patientAttentions, setPatientAttentions] = useState<AttentionsModel>(initialPatientAttentions);
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };
  const params = useParams<RouteParams>();
  const history = useHistory();

  const {
    mutateAsync: mutateAsyncSearchPatientAttentions,
    isLoading: patientsAttentionsLoading,
    isSuccess: patientsAttentionsSuccess,
  } = useSearchPatientsAttentions();
  useHandleDataLoading({
    currentPage,
    userCurrent,
    searchMutation: mutateAsyncSearchPatientAttentions,
    searchFunction: onGetPatientAttentions,
    setResults: setPatientAttentions,
    filtersData: {
      ...(filtersData || {}),
      serviceStatusIn: filtersData?.serviceStatusIn || DEFAULT_ALL_SERVICE_STATES,  //We're looking all attentions
      patientId: patientId || undefined //If we're looking for a specific patient
    },
    initial,
    setIsInitial,
    inputChange$,
    setCurrentPage
  });


  const showTableBody = (!patientsAttentionsLoading);
  const showLoader = (patientsAttentionsLoading);
  const hasSuccess = (patientsAttentionsSuccess);
  const elementsToShow: ISearchResult<IIncomingFields> = mapIncomingAttentionFields(patientAttentions);

  const { mutateAsync: asynGetPatientData, isLoading: loadingPatientData } =
    useGetPatientData()

  const [allDataPatient, setAllDataPatient] = useState<PatientModel>()

  const onGetPatient = async () => {
    const dataPatient = await asynGetPatientData(params.documentPatient)

    if (dataPatient?.data) {
      setAllDataPatient(dataPatient.data)
    }
  }

  useEffect(() => {
    if (params.documentPatient) {
      onGetPatient();
    }
  }, [params.documentPatient])


  const {
    resultingElements
  } = getBreadcrumbDetails({
    location,
    from: BREADCRUMB_ORIGIN.PATIENT_SERVICE_HISTORY,
    additionalData: allDataPatient
  })

  return (
    <React.Fragment>
      {showLoader && <Loader />}

      <Breadcrumbs
        name={""}
        prevPathName={""}
        currentPathName={""}
        elements={resultingElements}
      />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        <TableComponent
          elementsToShow={elementsToShow}
          pageSize={pageSize}
          handleChangePage={handleChangePage}
          setFiltersData={setFiltersData}
          hasSuccess={hasSuccess}
          showTableBody={showTableBody}
          filters={patientId ? [ALLOWED_FILTERS.ATTENTION_DATE, ALLOWED_FILTERS.RELEASE_DATE, ALLOWED_FILTERS.TEXT_ATTENTION] : [
            ALLOWED_FILTERS.ATTENTION_STATUS,
            ALLOWED_FILTERS.REGION,
            ALLOWED_FILTERS.ATTENTION_DATE,
            ALLOWED_FILTERS.TEXT
          ]}
          potentialActions={patientId ? [
            AVAILABLE_ACTIONS.ATTENTION_DETAILS,
            AVAILABLE_ACTIONS.VISITS_HISTORICAL,
          ] : [
            AVAILABLE_ACTIONS.EDIT_ATTENTION,
            AVAILABLE_ACTIONS.ATTENTIONS_HISTORY,
          ]}
          extraActions={[]}
          allowedFields={patientId ? HEADER_SORT_ORDER_ATTENTIONS_SPECIFIC_PATIENT : HEADER_SORT_ORDER_ATTENTIONS_PATIENTS}
          resultsLabel={'Atenciones'}
        />
      </div>
    </React.Fragment>
  );
};