import Button from 'components/Button';
import Annotations from 'features/PatientDashboard/components/Annotations';
import Recommendations from './Recommendations';
import WorkPlanList from './WorkPlanList';
import IconMeet from 'assets/icons/icon_meet.svg';
import IconCall from 'assets/icons/icon_call.svg';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import Tabs from 'components/Tabs';
import { MeetingVideoCall } from 'components/MeetingVideoCall';
import { useGetAllPlanToday, useGetIntakeMedication } from '../hooks/userPlanToday';
import Loader from 'components/Loader';
import { useCreateNotificationEmergency, useCreateNotificationCallDoctor } from 'features/ClinicHistory/hooks/useNotification'

interface Props {
  patientId: number
  userData: any
}

const PatientWorkPlan: React.FC<Props> = ({ patientId, userData }) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const tabs = [{ text: t('workplan') }, { text: t('recommendations') }, { text: t('annotations') }]
  const [isCall, setIsCall] = useState(false);
  const [listIntakeMedication, setlistIntakeMedication] = useState<any>([]);
  const { data, isLoading: loadingGetAllPlanToday , refetch:planRefetch} = useGetAllPlanToday();
  const { mutateAsync: getIntakeMedication, isLoading: loadingGetIntakeMedication } = useGetIntakeMedication();
  const { mutateAsync: createNotificationEmergency } = useCreateNotificationEmergency()
  const { mutateAsync: createNotificationCallDoctor } = useCreateNotificationCallDoctor()

  const planToday = data?.data
  const shoulFiredCall = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('meeting') === 'true'
  }
  const fetchListMedication = async () => {
    if(data?.data?.currentService){
      const listMedication = await getIntakeMedication(data.data.currentService.id)
      setlistIntakeMedication(listMedication?.data)
      planRefetch()
    }
  }
  const refetchPlan =  () => {
    console.log('refetchPlan');

    planRefetch()

  }
  useEffect(() => {
    if (data) {
     
      fetchListMedication()

    }
  }, [data]);

  const handleClickCallDoctor = () => {
    setIsCall(false)
    setTimeout(() => {
      createNotificationCallDoctor({
        patientId
      })
      setIsCall(true)
    }, 500)
  }

  const handleCloseMeeting = () => {
    setIsCall(false)
  }

  const handleClickCallEmergency = () => {
    setIsCall(false)
    setTimeout(() => {
      createNotificationEmergency({
        patientId
      })
      setIsCall(true)
    }, 500)
  }
  return (
    <div>

      {(!planToday?.currentService.id || planToday === undefined) ?
        <div className='h-[200px] flex items-center justify-center text-caption'>
          {loadingGetAllPlanToday || loadingGetIntakeMedication && <Loader />}
          Lo sentimos, Usted no cuenta con ningun servicio. </div>
        :
        <div>
          <div className="flex gap-2">
            <Button
              onClick={handleClickCallEmergency}
              type="button"
              variant='emergency'
              size="medium"
              className='flex items-center justify-center text-[12px] sm:h-[56px] leading-[16px] sm:text-[14px] leading-[16px] px-[13px] p-[16px]	' >
              <img src={IconCall} alt={IconCall} className='mr-[8px] sm:w-[24px] sm:w-[24px]  sm:h-[24px]' />
              {t('emergency')}
            </Button>
            <Button
              onClick={handleClickCallDoctor}
              type="submit"
              variant='primary'
              size="medium"
              className='flex items-center justify-center  text-[12px] sm:h-[56px] leading-[16px] sm:text-[14px] leading-[16px] px-[13px] p-[16px]	 bg-primary-main sm:w-[188px]' >
              <img src={IconMeet} alt={IconCall} className='mr-[8px] sm:w-[25px] sm:h-[25px]' />
              {t('call_the_doctor')}
            </Button>
          </div>
          <div className='pt-4'>
            {
              (shoulFiredCall() || isCall) &&
              <MeetingVideoCall
                planId={planToday?.currentService?.id}
                userId={userData?.id}
                userProfile={userData?.profile}
                getIsClose={handleCloseMeeting}
                initCall={shoulFiredCall() || isCall}
              />
            }
          </div>
          {windowSize.innerWidth < 800 &&
            <Tabs
              onClickStep={setStep}
              step={step}
              items={tabs}
            />
          }
          <div className=' grid sm:grid-cols-2 gap-4 mt-[24px]'>
            {(windowSize.innerWidth >= 800 ? true : step === 0) &&
              <div className='col-span-2 md:col-span-1'>
                {listIntakeMedication && 
                <WorkPlanList
                data={planToday?.medicaments}
                listIntakeMedications={listIntakeMedication}
                planId={planToday?.currentService?.id}
                patientId={patientId}
                serviceId={planToday?.currentService.id}
                refetchPlan={()=>refetchPlan()}
                />}
              </div>
            }
            {(windowSize.innerWidth >= 800 ? true : step === 1) &&
              <div className='col-span-2 md:col-span-1'>
                <Recommendations
                  data={planToday?.recommendations}
                />
              </div>
            }
            {(windowSize.innerWidth >= 800 ? true : step === 2) &&
              <div className='col-span-2'>
                <Annotations
                  data={planToday?.annotations}
                  planId={planToday?.currentService?.id}
                  patientId={patientId}
                  patientView={true}
                />
              </div>
            }
          </div></div>}
    </div>
  )
}
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default PatientWorkPlan
