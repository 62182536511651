import { FC, useEffect, useState, Fragment } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { useSearchPatientsAttentions } from 'features/Patients/hooks/usePatient';
import { AttentionModel } from 'features/shared/types/userType';
import AttentionDetailElement from 'assets/icons/icon_attention_details.svg';
import { AttentionStatusChip } from '../AttentionStatusChip';
import VerticalMenu from 'components/VerticalMenu';
import FileIcon from 'assets/icons/icon_file.svg';
import FolderIcon from 'assets/icons/icon_folder.svg';
import { useDownloadFile } from 'features/PatientServiceHistory/hooks/userPatientServiceHistory';
import { PatientModel } from 'features/Patient/models/Patient.model';
import { useGetPatientData } from 'features/shared/hooks/useGetPatientFullData';
import { onAlert } from 'components/Alert';
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from 'features/shared/utils/getBreadcrumbDetails';
import { ToolTipWrapper } from 'features/ClinicHistory/ListPatients/ToolTip';
import { formatDate } from 'features/shared/utils/text/formatDate';
import { attentionNumber, FieldElement, formatDateTimeComponent, getHospitalizationDays } from './index.snippets';

const Icon: FC<{ iconName: string }> = ({ iconName }) => {
    return (
        <img src={iconName} alt={iconName} className="sm:w-[16px] sm:h-[20px]" />
    );
};

interface IParams {
    documentPatient: string;
    attentionId: string;
}

export const AttentionsDetail: React.FC = () => {
    const [allDataPatient, setAllDataPatient] = useState<PatientModel>();
    const [attentionData, setAttentionData] = useState<AttentionModel | null>(null);

    const params = useParams<IParams>();
    const { mutateAsync: asyncGetAttentionsData, isLoading: loadingAttentionData } = useSearchPatientsAttentions();
    const { mutateAsync: asynGetPatientData } = useGetPatientData();
    const { mutateAsync: downloadFile } = useDownloadFile();

    useEffect(() => {
        onGetPatient();
    }, []);

    const onGetPatient = async () => {
        const dataPatient = await asynGetPatientData(params.documentPatient);

        if (dataPatient?.data) {
            setAllDataPatient(dataPatient.data);
        }
    };
    useEffect(() => {
        asyncGetAttentionsData({
            page: 1,
            inputChange$: null as any,
            patientId: params.documentPatient,
            attentionId: params.attentionId
        })
            .then(({ data }) => {
                const dataModel = Array.isArray(data?.data) ? data?.data[0] : null;
                if (dataModel) {
                    setAttentionData(dataModel);
                }
            })
            .catch((err) => {
                console.log({ err });
            });
    }, [asyncGetAttentionsData, params.documentPatient, params.attentionId]);

    const onDownloadFile = (fileType: string, epicrisisId?: number | null) => {
        if (!epicrisisId) {
            return;
        }
        downloadFile({ epicrisisId, fileType }).then(res => {
            if (res.data) {
                const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                const a = document.createElement('a');
                a.href = mediaType + res.data;
                a.download = `${allDataPatient?.name} ${allDataPatient?.lastname} ${fileType}.pdf`;
                a.click();
                a.remove();
            } else {
                onAlert.error(`No se cuenta ${fileType}`);
            }
        });
    };

    const { resultingElements } = getBreadcrumbDetails({
        location,
        from: BREADCRUMB_ORIGIN.PATIENT_ATTENTION_DETAIL,
        additionalData: allDataPatient,
    })

    return (
        <Fragment>
            {loadingAttentionData && <Loader />}
            <Breadcrumbs name={''} currentPathName={''} elements={resultingElements} />
            {
                attentionData &&
                <div className='w-full bg-white py-6 px-4 rounded-[16px] shadow-md mt-6'>
                    <div data-testid="Patients" className='p-4 rounded-[16px] border border-[#e2e2e2]'>
                        <div className='flex items-center'>
                            <div className='flex items-center'>
                                <Icon iconName={AttentionDetailElement} />
                                <div className='font-poppins font-normal text-base leading-6 tracking-[0.2px] flex ml-3 text-[#3b3e45]'>
                                    Detalle de la Atención
                                </div>
                                <AttentionStatusChip attentionStatusCode={attentionData?.status} />
                            </div>
                            {attentionData.epicrisisId && (
                                <div className='ml-auto'>
                                    <ToolTipWrapper text='Descargar PDF'>
                                        <VerticalMenu>
                                            <>
                                                <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `}>
                                                    <div onClick={() => onDownloadFile('epicrisis', attentionData.epicrisisId)} className='flex mx-4'>
                                                        <img className='w-[18px] h-[18px] mr-4' src={FolderIcon} alt="epicrisis" /> <span>Epicrisis</span>
                                                    </div>
                                                </li>
                                                <li className={`cursor-pointer py-4 hover:bg-grey-200 text-caption w-[133px] `}>
                                                    <div onClick={() => onDownloadFile('alta-medica', attentionData.epicrisisId)} className='flex mx-4'>
                                                        <img className='w-[18px] h-[18px] mr-4' src={FileIcon} alt="alta" /> <span>Alta de paciente</span>
                                                    </div>
                                                </li>
                                            </>
                                        </VerticalMenu>
                                    </ToolTipWrapper>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col xl:flex-row sm:grid sm:grid-cols-2 xl:grid-cols-4 gap-2 bg-[#f6f6f6] rounded-lg p-4 mt-4 gap-y-3'>
                            <div className='col'>
                                <FieldElement title='Nº de historia clínica' content={`${attentionData?.nhc || ""}`} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Fecha y hora de ingreso' content={formatDateTimeComponent(attentionData?.admissionDate)} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Nº de atención' content={attentionNumber(`${attentionData?.serviceId || "0"}`)} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Fecha y hora de inicio de atención' content={formatDateTimeComponent(attentionData?.serviceDate)} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Distrito' content={`${attentionData?.region}`} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Dirección' content={attentionData?.address} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Fecha de alta' content={attentionData?.releaseDate ? formatDate(attentionData?.releaseDate) : ""} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Días de hospitalización' content={getHospitalizationDays(attentionData)} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Procedencia del ingreso' content={attentionData?.admissionOrigin} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Motivo de ingreso' content={attentionData?.admissionReasonLabel} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Tipo' content={attentionData?.serviceTypeLabel} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Lugar de atención' content={attentionData?.attentionPlaceLabel} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Servicio' content={attentionData?.providedServiceLabel} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Beneficios' content={attentionData?.benefitsLabel} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Servicio actual' content={attentionData?.medicalSpeciality} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Diagnóstico' content={attentionData?.diagnosis} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Médico evaluador' content={attentionData?.evaluatorDoctorFullName} />
                            </div>
                            <div className='col'>
                                <FieldElement title='Hogar habilitado' content={attentionData?.homeEnabled ? "Sí" : "No"} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};