import { AttentionModel } from "features/shared/types/userType";
import { formatDate } from "features/shared/utils/text/formatDate";
import moment from "moment";
import { FC } from "react";


export const FieldElement: FC<{ title: string; content: any }> = ({ title, content }) => {
    return (
        <div>
            <div style={{ color: "rgba(88, 90, 91, 1)", fontSize: "12px" }}>
                {title}
            </div>
            <div style={{ fontSize: "16px" }}>
                {content}
            </div>
        </div>
    );
};

export function formatDateTimeComponent(dateLike?: string) {
    if (!dateLike) {
        return "";
    }
    return (
        <div>
            <span style={{ marginRight: "10px" }}>
                {formatDate(dateLike, "DD/MM/YYYY")}
            </span>
            <span>{formatDate(dateLike, "h:mm A")}</span>
        </div>
    );
}

export function formatDateComponent(dateLike?: string) {
    if (!dateLike) {
        return "";
    }
    return (
        <div>
            <span>{formatDate(dateLike, "DD/MM/YYYY")}</span>
        </div>
    );
}

export function attentionNumber(formatNumber: string) {
    const baseNumber = "000000";
    return baseNumber.slice(0, baseNumber.length - formatNumber.length) + formatNumber;
}

export function getHospitalizationDays(attentionElement: AttentionModel) {
    if (attentionElement.plannedReleaseDate) {
        return moment(attentionElement.plannedReleaseDate).diff(attentionElement.admissionDate, 'days')
    }
    return "";
}