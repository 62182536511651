import React, { forwardRef, useEffect, useState } from "react"
import ArrowDownIcon from 'assets/icons/icon_chevron_down.svg';
import Select from 'react-select'
import "./index.css"

interface InputSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  required?: boolean;
  label?: string;
  errorText?: string;
  helperText?: string;
  value?: any;
  onSelect?: ((val: any) => any);
  classNameWrapper?: string
  inputRef?: any
  hidePlaceholder?: boolean
}

const InputSelect = forwardRef<HTMLSelectElement, InputSelectProps>(({
  className,
  classNameWrapper,
  required,
  label,
  errorText,
  helperText,
  value,
  onSelect,
  inputRef,
  ...props
}, ref) => {
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [hasSelected, setHasSelected] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      onHasValue(value);
    }
  }, [value])

  const onHasValue = (val: any) => {
    setHasValue(val.toString().length > 0);
  }

  const onChangeSelect = (val: any) => {
    if (val == '') {
      onHasValue("");
      onSelect && onSelect(null);
    } else {
      let resultingValue = "";
      if (Array.isArray(val)) {
        resultingValue = val[0].value;
      } else {
        resultingValue = val.value;
      }
      onHasValue(resultingValue);
      onSelect && onSelect(resultingValue);
    }
  }

  let calculatedOptions: {
    value: any,
    label: string
  }[] = [];


  Array.isArray((props as any).children) ? (props as any).children.forEach((element: any) => {
    if(typeof element === "boolean"){
      return;
    }

    if (Array.isArray(element?.props?.children)) {
      const hasObjects = element?.props?.children.find((element: any) => {
        return typeof element === 'object';
      })
      if (hasObjects) {
        element?.props?.children.forEach((element: any) => {
          if (element?.props?.children && element?.props?.value) {
            console.log({
              element
            });
            calculatedOptions.push({
              value: element?.props?.value,
              label: [...element?.props?.children].join('').trim()
            })
          }
        })
      } else {
        calculatedOptions.push({
          value: element?.props?.value,
          label: [...element?.props?.children].join('').trim()
        });
      }
    } else {
      let chidrenValues = [];
      try {
        chidrenValues = [...element?.props?.children];
      } catch (e) {
        chidrenValues = [String(element?.props?.children).valueOf()]
      }
      calculatedOptions.push({
        value: element?.props?.value,
        label: chidrenValues.join('').trim()
      });
    }
  }) : [];

  calculatedOptions = [{
    label: "Seleccionar...",
    value: "",
  }, ...calculatedOptions]

  let placeholder = helperText ? helperText : (props.placeholder || "Seleccionar...");
  const selectedValueOption = calculatedOptions.find((element) => element.value == value);
  const selectedDefaultValue = calculatedOptions.find((element) => element.label == "Seleccionar...");

  return (
    <div className={classNameWrapper}>
      <div
        className='
          w-full max-w-full h-[56px] text-body1 relative cursor-pointer 
          flex items-center justify-start
          custom-select
        '
      >
        <Select
          className={`
            ${hasValue ? 'text-init' : 'text-transparent'} 
            w-full h-full rounded-[16px] text-grey-600 pt-4
            focus:text-grey-600
            bg-grey-70
            ${isValid ? '' : 'px-[calc(16px-1px)] border-solid border-[1px] !border-error-main'} 
            ${className}
          `}
          onChange={onChangeSelect}
          value={selectedValueOption}
          options={calculatedOptions}
          ref={inputRef}
          placeholder={selectedDefaultValue ? "" : placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          classNames={{
            option: () => "custom-select__option",
            menu: () => "custom-select__menu",
            menuList: () => "custom-select__menu",
            singleValue: (state) => {
              const hide = state.data.label === "Seleccionar...";
              return `custom-select__single-value ${hide ? 'hide-selected' : ''}`
            },
          }}
        />
        <label
          className={`
                px-[16px] absolute text-gray-600 pointer-events-none 
                transition duration-150 ease-out 
                ${(hasValue && selectedValueOption) ? 'mb-6 text-caption text-neutral-600 ease-in' : ''}
                peer-focus:text-caption peer-focus:mb-6 
                ${isValid ? '' : 'text-error-main'}
                overflow-hidden whitespace-nowrap w-full
              `}
        >
          {label}
          {required ? (<span className="font-semibold">*</span>) : ''}
        </label>
      </div>
      {
        isValid ? (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-gray-600 
            ${helperText ? '' : 'hidden'}
          `}>
            {helperText}
          </p>
        ) : (
          <p className={`
            px-[16px] text-caption p-0 pt-1 text-error-main 
            ${hasValue && errorText ? '' : 'hidden'}
          `}>
            {hasValue ? 'Este campo es obligatorio' : errorText}
          </p>
        )
      }
    </div>
  )
})

export default InputSelect;
