import { AttentionsModel, ISearchResult, PatientsModel } from "features/shared/types/userType";

export interface IIncomingFields {
    name: string;
    lastname: string;
    documentType: string;
    document: string;
    country: string;
    nhc: number;
    admissionDate: string;
    diagnosis?: string;
    releaseDate?: string;
    region?: string;
    address?: string;
    phone?: string;
    patientId?: number;
    id?: number;
    lat?: string;
    lng?: string;
    hospitalizationDaysDiffRelease?: string,
    serviceId?: number;
    serviceStatus?: string;
    evaluatorDoctorFullName?: string;
    plannedReleaseDate?: string;
}

export const mapIncomingAttentionFields = (patientAttentions: AttentionsModel): ISearchResult<IIncomingFields> => ({
    currentPage: patientAttentions.currentPage,
    total: patientAttentions.total,
    totalPages: patientAttentions.totalPages,
    totalPatients: patientAttentions.totalPatients,
    data: patientAttentions?.data?.map((item) => ({
        id: item.serviceId || undefined,
        lastname: item.lastname,
        roleId: item.regionId,
        documentType: item.documentType,
        document: item.document,
        country: item.country,
        nhc: item.nhc,
        phone: undefined,
        address: undefined,
        lat: undefined,
        lng: undefined,
        reference: undefined,
        admissionDate: item.admissionDate,
        name: item.name,
        region: item.region,
        releaseDate: item.releaseDate,
        diagnosis: item.diagnosis,
        patientId: item.patientId,
        serviceId: item.serviceId,
        serviceStatus: item.status,
        evaluatorDoctorFullName: item.evaluatorDoctorFullName,
        plannedReleaseDate: item.plannedReleaseDate,
    }))
})

export const mapIncomingPatientsListField = (patientResults: PatientsModel): ISearchResult<IIncomingFields> => ({
    currentPage: patientResults.currentPage,
    total: patientResults.total,
    totalPages: patientResults.totalPages,
    data: patientResults.data.map((item) => {
        return {
            id: item.id,
            lastname: item.lastname,
            roleId: item.profile,
            documentType: item.documentType,
            document: item.document,
            country: item.country,
            nhc: item.nhc,
            phone: undefined,
            address: item?.address?.name ? item?.address?.name : typeof item?.address === "string" ? item?.address : undefined,
            lat: item?.address?.lat,
            lng: item?.address?.lng,
            reference: undefined,
            admissionDate: item.admissionDate,
            name: item.name,
            region: item.region,
            releaseDate: item.releaseDate,
            diagnosis: item.diagnosis,
            hospitalizationDaysDiffRelease: item.hospitalizationDaysDiffRelease,
            hospitalizationDaysDiffCurrent: item.hospitalizationDaysDiffCurrent,
        }
    })
})