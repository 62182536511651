import React, { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

import useUser from 'features/shared/hooks/useUser';
import { ISearchResult, PatientsModel } from 'features/shared/types/userType';
import Breadcrumbs from 'components/Breadcrumbs';

import Loader from '../../../components/Loader';
import { ALLOWED_FILTERS, IFiltersData } from './Table/TableUpperActions';
import { HEADER_SORT_ORDER_HISTORY, onGetListPatients, useHandleDataLoading } from './index.utils';
import { IIncomingFields, mapIncomingPatientsListField } from './Table/Table.utils';
import { TableComponent } from './Table/Table';
import { AVAILABLE_ACTIONS } from 'global/constants/enums';
import { useGetListPatients } from '../hooks/usePatient';
import { BREADCRUMB_ORIGIN, getBreadcrumbDetails } from 'features/shared/utils/getBreadcrumbDetails';
import { ROLE } from 'global/constants/roles';

const pageSize = 10;
const inputChange = new BehaviorSubject('');
const inputChange$ = inputChange.asObservable();

const initialPatientAttentions: PatientsModel = {
  currentPage: 1,
  data: [],
  total: 1,
  totalPages: 1,
};

export const HistoryComponent: React.FC = () => {
  const { userCurrent } = useUser();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initial, setIsInitial] = useState(true);
  const [filtersData, setFiltersData] = useState<IFiltersData>();
  const [patientAttentions, setPatientAttentions] = useState<PatientsModel>(initialPatientAttentions);
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const {
    isLoading: patientsAttentionsLoading,
    mutateAsync: mutateAsyncSearchPatientAttentions,
    isSuccess: patientsAttentionsSuccess,
  } = useGetListPatients();
  const healthcareProfessionalId = userCurrent?.roleSlug === ROLE.PROFESSIONAL_HEALTH ? userCurrent?.id || "LOADING" : undefined;

  useHandleDataLoading({
    currentPage,
    userCurrent,
    searchMutation: mutateAsyncSearchPatientAttentions,
    searchFunction: onGetListPatients,
    setResults: setPatientAttentions,
    filtersData: {
      ...(filtersData || {}),
      serviceStatusIn: [3],  //We're looking only completed service records
      specialistId: healthcareProfessionalId
    },
    initial,
    setIsInitial,
    inputChange$,
    setCurrentPage
  });


  const showTableBody = (!patientsAttentionsLoading);
  const showLoader = (patientsAttentionsLoading);
  const hasSuccess = (patientsAttentionsSuccess);
  const elementsToShow: ISearchResult<IIncomingFields> = patientAttentions ? mapIncomingPatientsListField(patientAttentions) : [] as any;
  const breadcrumbsData = getBreadcrumbDetails({
    from: BREADCRUMB_ORIGIN.HISTORY,
    location: location as any,
    additionalData: {}
  });

  return (
    <React.Fragment>
      {showLoader && <Loader />}

      <Breadcrumbs
        name={''}
        currentPathName={''}
        elements={breadcrumbsData.resultingElements}
      />

      <div
        data-testid="Patients"
        className="w-full bg-white pt-6 pb-6 rounded-[16px] shadow-md mt-6"
      >
        {
          userCurrent ? <TableComponent
            elementsToShow={elementsToShow}
            pageSize={pageSize}
            handleChangePage={handleChangePage}
            setFiltersData={setFiltersData}
            hasSuccess={hasSuccess}
            showTableBody={showTableBody}
            filters={[
              ALLOWED_FILTERS.REGION,
              ALLOWED_FILTERS.ATTENTION_DATE,
              ALLOWED_FILTERS.RELEASE_DATE,
              ALLOWED_FILTERS.TEXT
            ]}
            potentialActions={[
              AVAILABLE_ACTIONS.EDIT_PATIENT,
              AVAILABLE_ACTIONS.ATTENTIONS_HISTORY,
              AVAILABLE_ACTIONS.NEW_ATTENTION,
            ]}
            extraActions={[]}
            allowedFields={HEADER_SORT_ORDER_HISTORY}
            resultsLabel='Pacientes'
          /> : null
        }
      </div>
    </React.Fragment>
  );
};