import { Fragment } from 'react'

const Schedule = (props: any) => {
  const onClickIcon = () => {
    props.onClickIcon();
  }
  return (
    <Fragment>
      <div onClick={onClickIcon}
        className={`${props.className} w-full max-w-full h-[56px] text-body1 relative   flex items-center justify-start `} >
        <div className=' pr-[45px] px-[16px] w-full h-[56px] rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none text-body1 '>
          <label
            className={`top-2	left-0 px-[16px] absolute text-gray-600 text-caption  `}
            style={{
              marginTop: "-4px"
            }}
          >
            {props.title}
          </label>
          <div
            className={`flex `}>
            <div className=" overflow-auto scrollbar">
              {props.description}
            </div>
            <img
              src={props.icon}
              className="w-[24px] h-[24px] absolute right-[16px] "
              alt={props.icon}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Schedule
