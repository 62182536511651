export enum SERVICE_STATES {
    PENDING = 1,
    IN_PROGESS = 2,
    FINISHED = 3
}

export const ALLOWED_SERVICE_STATES_LABELS = {
    [`${SERVICE_STATES.PENDING}`]: "Pendiente",
    [`${SERVICE_STATES.IN_PROGESS}`]: "En Progreso",
    [`${SERVICE_STATES.FINISHED}`]: "Atendido"
}

export const DEFAULT_ALL_SERVICE_STATES = [
    SERVICE_STATES.PENDING,
    SERVICE_STATES.IN_PROGESS,
    SERVICE_STATES.FINISHED
]