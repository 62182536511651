import { SetStateAction } from "react"
import { TableHeadElement } from "./TableHead"
import { TableBodyElement } from "./TableBody"
import { ISearchResult } from "features/shared/types/userType"
import { IIncomingFields } from "./Table.utils"
import { NoResultsFound } from "../NoResultsFound"
import Pagination from "components/Pagination"
import { ALLOWED_FILTERS, EXTRA_ACTIONS, IFiltersData, TableUpperActions } from "./TableUpperActions"
import { HEADER_ELEMENT_KEYS } from "../index.utils"
import { AVAILABLE_ACTIONS } from "global/constants/enums"

export const TableComponent = ({
    elementsToShow,
    pageSize,
    handleChangePage,
    setFiltersData,
    hasSuccess,
    showTableBody,
    filters,
    potentialActions,
    extraActions,
    allowedFields,
    resultsLabel
}: {
    elementsToShow: ISearchResult<IIncomingFields>,
    pageSize: number,
    handleChangePage: (page: number) => void,
    setFiltersData: (value: SetStateAction<IFiltersData | undefined>) => void,
    hasSuccess: boolean,
    showTableBody: boolean,
    filters: ALLOWED_FILTERS[]
    potentialActions: AVAILABLE_ACTIONS[]
    extraActions: EXTRA_ACTIONS[],
    allowedFields: HEADER_ELEMENT_KEYS[]
    resultsLabel: string
}) => {
    return <div>
        <TableUpperActions
            onFiltersChanged={(data) => {
                setFiltersData(data);
            }}
            filters={filters}
            extraActions={extraActions}
            patientsNumber={hasSuccess ? (elementsToShow?.totalPatients || elementsToShow?.total || 0).toString() : ""}
            resultsLabel={resultsLabel}
        />
        {
            (showTableBody && elementsToShow?.data?.length) ?
            <section data-testid="Table" className="w-full overflow-auto scrollbar">
                <table className="min-w-full divide-y divide-gray-200 border-collapse" style={{
                    marginBottom: "2em"
                }}>
                    <TableHeadElement
                        allowedFields={allowedFields}
                    />
                    <TableBodyElement
                        potentialActions={potentialActions}
                        listResults={elementsToShow}
                        allowedFields={allowedFields}
                    />
                </table>
            </section> : null
        }
        {elementsToShow?.data?.length <= 0 && <NoResultsFound />}
        {elementsToShow?.data?.length >= 1 && <div className="flex justify-end mt-[24px] px-4">
            <Pagination
                currentPage={elementsToShow?.currentPage}
                totalCount={elementsToShow?.total}
                pageSize={pageSize}
                onPageChange={handleChangePage}
            />
        </div>}
    </div>
}