import "./TableUpperActions.css";
import CardDate from "features/ClinicHistory/ListPatients/CardDate/CardDate";
import moment from 'moment';
import { useEffect, useState } from "react";

const minDate = moment().subtract(2, "year").toDate();
const maxDate = moment().add(2, "day").toDate();

export interface ICardDateProps {
  startDateRange?: Date,
  endDateRange?: Date
}
export const SelectDateRangeFunction = ({
  onDateSelected,
  label,
  id
}: {
  onDateSelected: (data: ICardDateProps) => void
  label: string;
  id: string;
}) => {
  const [selectedDate, setSelectedDate] = useState<ICardDateProps>({
    startDateRange: undefined,
    endDateRange: undefined
  });

  useEffect(() => {
    onDateSelected(selectedDate)
  }, [selectedDate])

  return (<div id={"TableUpperActionsDatePickerParent_" + id} style={{
    position: "relative",
    display: "flex",
    justifyContent: "left",
    alignItems: "end",
    WebkitAlignItems: "center"
  }} className="TableUpperActionsDatePickerParent w-full">
    <div className="CardDateContainer w-full"><CardDate
      setData={(data) => {
        setSelectedDate(data)
      }}
      id={"TableUpperActionsCardDate_" + id}
      minDate={minDate}
      maxDate={maxDate}
      onCanceled={() => {
        setSelectedDate({
          startDateRange: undefined,
          endDateRange: undefined
        })
      }}
      label={label}
    />
    </div>
  </div>)
}