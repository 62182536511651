import { AttentionsModel, PatientsModel } from 'features/shared/types/userType'
import fetcher from 'features/shared/utils/fetcher';
import { useMutation } from 'react-query'
import { getListPatientsCO } from './patient.service'
import { Observable } from 'rxjs';


export interface ISearchAttentionsPayload {
  region?: string,
  attentionDateStart?: Date,
  attentionDateEnd?: Date,
  releaseDateStart?: Date,
  releaseDateEnd?: Date,
  searchText?: string,
  page: number,
  inputChange$: Observable<string>
  serviceStatusIn?: number[]
  patientId?: string;
  attentionId?: string;
  healthcareProfessionalId?: string
}
export function useGetListPatients() {
  return useMutation((query: string) => getListPatients(query));
}
export function useGetAttentsListPatients() {
  return useMutation((query: string) => getAttentsListPatients(query));
}
export function useSearchPatientsAttentions() {
  return useMutation((query: ISearchAttentionsPayload) => searchAttentions(query));
}
export function useGetListPatientsCO() {
  return useMutation((page: number) => getListPatientsCO(page));
}

export async function getListPatients(query: string) {
  return await fetcher.get<{ data: PatientsModel }>('/patients' + query);
}
export async function getAttentsListPatients(query: string) {
  return await fetcher.get<{ data: PatientsModel }>('/attention/patients' + query);
}

export async function searchAttentions(payload: ISearchAttentionsPayload) {
  const urlSearchParams = new URLSearchParams();
  if (payload.page) {
    urlSearchParams.append('page', `${payload.page}`);
  }
  if (payload.region) {
    urlSearchParams.append('region', payload.region);
  }
  if (payload.attentionDateStart) {
    urlSearchParams.append('attentionDateStart', payload.attentionDateStart.toISOString());
  }
  if (payload.attentionDateEnd) {
    urlSearchParams.append('attentionDateEnd', payload.attentionDateEnd.toISOString());
  }
  if (payload.releaseDateStart) {
    urlSearchParams.append('releaseDateStart', payload.releaseDateStart.toISOString());
  }
  if (payload.releaseDateEnd) {
    urlSearchParams.append('releaseDateEnd', payload.releaseDateEnd.toISOString());
  }
  if (payload.searchText) {
    urlSearchParams.append('searchText', payload.searchText);
  }
  if (Array.isArray(payload.serviceStatusIn)) {
    urlSearchParams.append('serviceStatusIn', JSON.stringify(payload.serviceStatusIn));
  }
  if (payload.patientId) {
    urlSearchParams.append('referencedPatientId', payload.patientId);
  }
  if (payload.attentionId) {
    urlSearchParams.append('attentionId', payload.attentionId);
  }
  if (payload.healthcareProfessionalId) {
    urlSearchParams.append('healthcareProfessionalId', payload.healthcareProfessionalId);
  }
  const query = urlSearchParams.toString();
  //TODO: Define types.
  return await fetcher.get<{ data: AttentionsModel }>('/patients/all/attentions?' + query);
}
