import { useHistory } from 'react-router-dom'

import HomeIcon from 'assets/icons/icon_home.svg'
import ChevronRightIcon from 'assets/icons/icon_chevron_right.svg'
import { BreadcrumbsProps } from './index.interface'


const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  name,
  currentPathName,
  prevPathName,
  onClickHome,
  onClickPrev,
  className = '',
  elements
}) => {
  const history = useHistory()

  const handleClickPrevPath = () => {
    if (onClickPrev) {
      onClickPrev()
    }
  }

  const handleClickHome = () => {
    if (onClickHome) {
      onClickHome()
    } else {
      history.push(`/`)
    }
  }

  const filterActive = 'invert(33%) sepia(86%) saturate(1046%) hue-rotate(154deg) brightness(99%) contrast(101%)';

  if (elements?.length) {

    return (
      <div className={`flex flex-wrap gap-2 items-center ${className}`}>
        <span className="text-headline4 font-[600] text-grey-600">{elements.length ? elements[0]?.name : ""}</span>
        <div className="h-8 w-[1px] bg-grey-400 mr-2"></div>
        <img
          src={HomeIcon}
          alt="home"
          onClick={handleClickHome}
          className="cursor-pointer"
          style={{ filter: filterActive }}
        />
        <div className='flex flex-row'>
          {
            elements.map((element, index) => {
              if (index == 0) {
                return;
              }
              return (
                <div key={element.name} className='col flex'>
                  <img src={ChevronRightIcon} alt={element.name} />
                  {
                    index === elements.length - 1 ? <span
                      className="text-text-body1"
                    >
                      {element.name}
                    </span> : <span
                      onClick={element.onClick}
                      className={`${element.onClick ? 'cursor-pointer text-primary-main' : ''} text-text-body1`}
                    >
                      {element.name}
                    </span>
                  }

                </div>)
            })
          }
        </div>
      </div>
    )
  }

  return (
    <div className={`flex flex-wrap gap-2 items-center ${className}`}>
      <span className="text-headline4 font-[600] text-grey-600">{name}</span>
      <div className="h-8 w-[1px] bg-grey-400 mr-2"></div>
      <img
        src={HomeIcon}
        alt="home"
        onClick={handleClickHome}
        className="cursor-pointer"
        style={{ filter: filterActive }}
      />
      {prevPathName && (
        <>
          <img src={ChevronRightIcon} alt={prevPathName} />
          <span
            onClick={handleClickPrevPath}
            className="cursor-pointer text-primary-main text-text-body1"
          >
            {prevPathName}
          </span>
        </>
      )}
      <img src={ChevronRightIcon} alt={currentPathName} />
      <span className="text-text-body1 text-grey-600">{currentPathName}</span>
    </div>
  )
}

export default Breadcrumbs

